<template>
  <v-card>
    <v-card-title>Not Found</v-card-title>
    <v-card-text>
      <h2>This is not the page you are looking for...</h2>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped></style>
