<template>
  <div v-if="!access">
    <v-row>
      <v-col offset="4" cols="4">
        <v-card title="Enter Your Access PIN">
          <v-card-text>
            <v-text-field
              class="pin"
              type="password"
              variant="outlined"
              v-model="pin"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <component
      v-if="access && assessment"
      :is="formData.type"
      :assessment="assessment"
    />
  </div>
</template>

<script>
import BiaPrework from "@/components/BiaPrework.vue";
import SystemElementMaturities from "@/components/SystemElementMaturities";
import { FirestoreService } from "@/services/FirestoreService";
import { CqnaAssessment } from "@/entities/CqnaAssessment";
export default {
  name: "CqnaQuestionnaire",
  components: { BiaPrework, SystemElementMaturities },
  props: {
    assessmentId: String,
  },
  provide() {
    return { assessmentId: this.assessmentId };
  },
  data() {
    return {
      formData: null,
      pin: "",
      access: true,
      assessment: null,
      questionGroups: [],
    };
  },
  watch: {
    pin(val) {
      if (val === this.formData.pin) this.access = true;
    },
  },
  async created() {
    const assessmentService = new FirestoreService("cqna");
    this.formData = await assessmentService.getById(this.assessmentId);
    if (this.formData && this.formData.pin) this.access = false;
    if (!this.formData || this.formData.archived) await this.$router.push("/");
    else this.assessment = new CqnaAssessment(this.assessmentId, this.formData);
  },
};
</script>

<style>
.pin input {
  text-align: center;
  font-size: 2rem;
}
</style>
