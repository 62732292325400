<template>
  <v-toolbar color="surface">
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer />
    <v-btn size="sm" icon="mdi-close" @click="close" />
  </v-toolbar>
  <v-form v-model="valid" ref="form" @submit.prevent="submit">
    <v-text-field
      label="Process Name"
      v-model="name"
      variant="outlined"
      class="mb-2"
      :rules="nameRules"
    />
    <v-textarea
      label="Process Description"
      v-model="description"
      variant="outlined"
      class="mb-2"
    />
    <v-card variant="outlined" class="mb-2">
      <template #title>
        <span class="text-body-1"> Process-Dependent Information Systems </span>
      </template>
      <template #subtitle>
        Please select all Information Systems that this process is dependent on.
        If there are Information Systems that are used to support this process
        that are not in the list below, please add them to the Process
        Description above.
      </template>
      <v-container>
        <v-chip-group v-model="informationSystems" column multiple>
          <v-chip
            filter
            v-for="informationSystem in assessment.meta.informationSystems"
            :value="informationSystem.id"
            :key="informationSystem.id"
          >
            {{ informationSystem.name }}
          </v-chip>
        </v-chip-group>
      </v-container>
    </v-card>
  </v-form>
  <v-btn block color="secondary" @click="submit">Submit</v-btn>
</template>
<script>
import { CqnaAssessment } from "@/entities/CqnaAssessment";
import { v4 } from "uuid";

export default {
  name: "BiaProcessForm",
  props: {
    assessment: CqnaAssessment,
    model: Object,
  },
  emits: ["submit", "update", "close"],
  data() {
    return {
      valid: true,
      name: "",
      description: "",
      informationSystems: [],
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  computed: {
    title() {
      return `${this.model ? "Update" : "Add"} A Business Process`;
    },
  },
  methods: {
    close() {
      this.name = "";
      this.description = "";
      this.informationSystems = [];

      this.$emit("close");
    },
    async submit() {
      const { name, description, informationSystems } = this;
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        let process = { name, description, informationSystems };
        if (this.model) this.$emit("update", { ...this.model, ...process });
        else this.$emit("submit", { ...process, id: v4() });
        this.$refs.form.reset();
      }
    },
  },
  created() {
    if (this.model) {
      this.name = this.model.name;
      this.description = this.model.description;
      this.informationSystems = this.model.informationSystems;
    }
  },
};
</script>
