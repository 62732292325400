<template>
  <v-window-item v-for="question in questions" :key="question.id">
    <question-window
      :oscal="oscal"
      :question="question"
      :scale-options="scaleOptions"
      @answer="(answer) => $emit('change', question, answer)"
    />
  </v-window-item>
</template>
<script>
import QuestionWindow from "@/components/QuestionWindow";

export default {
  name: "QuestionsWindow",
  components: { QuestionWindow },
  emits: ["change"],
  props: {
    oscal: {},
    scaleOptions: {},
    questions: Array,
  },
  data() {
    return {};
  },
};
</script>
