<template>
  <v-list lines="three">
    <v-list-item
      v-for="systemElement in systemElements"
      :key="systemElement.id"
      :title="systemElement.title"
      :subtitle="`${systemElement.questions.length} Controls`"
    >
      <template #prepend>
        <v-progress-circular
          class="mt-2 mr-2"
          :model-value="systemElement.progress"
          size="50"
          color="#0552E9"
        >
          <span style="color: #03dac5"> {{ systemElement.progress }}% </span>
        </v-progress-circular>
      </template>
      <template #append>
        <question-control-buttons
          @click:start="select"
          @click:continue="select"
          :question-group="systemElement"
        />
      </template>
    </v-list-item>
  </v-list>
</template>
<script>
import QuestionControlButtons from "@/components/QuestionControlButtons";

export default {
  name: "system-element-list",
  components: { QuestionControlButtons },
  props: {
    calculateProgress: Function,
    systemElements: Array,
  },
  methods: {
    select(systemElement) {
      this.$emit("selected", systemElement);
    },
  },
};
</script>
