<template>
  <v-card>
    <v-toolbar color="secondary">
      <v-toolbar-title>
        {{ assessment.meta.name }}: Critical Business Processes
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="canAdd"
        variant="tonal"
        class="mr-2"
        @click="toggleProcessForm"
      >
        Add A Business Process
      </v-btn>
      <v-chip class="mr-3">
        <v-avatar size="x-small" color="surface" class="mr-2"
          >{{ remainingProcesses }}
        </v-avatar>
        Processes Remaining
      </v-chip>
      <v-btn icon="mdi-home" @click="$emit('open-contact')" />
    </v-toolbar>
    <v-container>
      <bia-process-form
        v-if="showProcessForm"
        :assessment="assessment"
        :model="selectedProcess"
        @submit="addProcess"
        @update="updateProcess"
        @close="toggleProcess"
      />
      <v-container
        v-else-if="remainingProcesses === assessment.meta.numOfProcesses"
      >
        <p>
          Please enter all Business Processes critical to the operation of this
          Business Unit.
        </p>
        <p>
          There are currently
          {{ remainingProcesses }} remaining Business Processes available to be
          entered.
        </p>
      </v-container>
      <v-container v-else>
        <v-toolbar
          v-if="!assessment.completed && !showProcessForm"
          color="surface"
        >
          <v-spacer />
          <v-btn
            variant="tonal"
            color="blue"
            v-if="hasProcesses"
            @click="toggleVerifySubmit"
          >
            Submit All Data
          </v-btn>
        </v-toolbar>
        <v-list lines="three" variant="tonal">
          <v-list-item
            v-for="process in processes"
            :key="process.name"
            :title="process.name"
            :subtitle="process.description"
            class="mb-2"
          >
            <template #default>
              <v-chip-group column>
                <v-chip
                  v-for="informationSystemId in process.informationSystems"
                  :key="informationSystemId"
                  class="mr-2"
                >
                  {{ getInformationSystem(informationSystemId).name }}
                </v-chip>
              </v-chip-group>
            </template>
            <template #append>
              <v-btn
                v-if="!assessment.completed"
                icon="mdi-pencil"
                class="mr-2"
                @click="toggleProcess(process)"
              />
              <v-btn
                v-if="!assessment.completed"
                icon="mdi-delete"
                @click="toggleVerifyDelete(process)"
              />
            </template>
          </v-list-item>
        </v-list>
      </v-container>
    </v-container>
  </v-card>
  <v-dialog v-model="verifySubmit" max-width="500">
    <v-card title="Are you sure?">
      <v-card-text>
        <p class="mb-2">
          This will complete the assessment and submit all data for review.
        </p>
        <p class="mb-2">
          Please make sure you have entered all Business Processes critical to
          the operation of this Business Unit. There are currently
          {{ remainingProcesses }} remaining Business Processes available to be
          entered.
        </p>
        <p class="mb-2">This CANNOT be undone once submitted.</p>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-btn color="secondary" variant="tonal" @click="toggleVerifySubmit">
            Cancel
          </v-btn>
          <v-btn color="blue" variant="tonal" @click="completeAssessment">
            Submit
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-if="selectedProcess" v-model="verifyDelete" max-width="500">
    <v-card title="Are you sure?">
      <v-card-text>
        <p class="mb-2">
          This will delete the Business Process: "{{ selectedProcess.name }}".
        </p>
        <p class="mb-2">This CANNOT be undone.</p>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-btn color="secondary" variant="tonal" @click="toggleVerifyDelete">
            Cancel
          </v-btn>
          <v-btn color="red" variant="tonal" @click="deleteProcess">
            Delete
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BiaProcessForm from "@/components/BiaProcessForm.vue";
import { CqnaAssessment } from "@/entities/CqnaAssessment";

export default {
  name: "BiaProcesses",
  components: { BiaProcessForm },
  emits: ["open-contact"],
  props: {
    assessment: CqnaAssessment,
  },
  data() {
    return {
      processes: [],
      showProcessForm: false,
      selectedProcess: null,
      verifySubmit: false,
      verifyDelete: false,
    };
  },
  computed: {
    canAdd() {
      return (
        !this.assessment.completed &&
        !this.showProcessForm &&
        !!this.remainingProcesses
      );
    },
    hasProcesses() {
      return !!this.processes.length;
    },
    remainingProcesses() {
      return this.assessment.meta.numOfProcesses - this.processes.length;
    },
  },
  methods: {
    toggleProcessForm() {
      this.showProcessForm = !this.showProcessForm;
    },
    toggleVerifySubmit() {
      this.verifySubmit = !this.verifySubmit;
    },
    toggleVerifyDelete(process = null) {
      this.selectedProcess = process;
      this.verifyDelete = !this.verifyDelete;
    },
    getInformationSystem(id) {
      return this.assessment.meta.informationSystems.find((iS) => iS.id === id);
    },
    async addProcess(process) {
      this.processes = [...this.processes, process];

      await this.assessment.setAnswer("processes", this.processes);

      this.toggleProcessForm();
    },
    toggleProcess(process = null) {
      this.selectedProcess = process;

      this.toggleProcessForm();
    },
    async updateProcess(process) {
      const index = this.processes.findIndex((p) => p.id === process.id);

      this.processes.splice(index, 1, process);

      await this.assessment.setAnswer("processes", this.processes);

      this.toggleProcessForm();
    },
    async deleteProcess() {
      const index = this.processes.findIndex(
        (p) => p.id === this.selectedProcess.id
      );

      this.toggleVerifyDelete();

      this.processes.splice(index, 1);

      await this.assessment.setAnswer("processes", this.processes);
    },
    async completeAssessment() {
      await this.assessment.setCompleted();
    },
  },
  created() {
    this.processes = this.assessment.answers.processes ?? [];
    if (this.remainingProcesses === this.assessment.meta.numOfProcesses) {
      this.showProcessForm = true;
    }
  },
};
</script>
