<template>
  <v-item-group :model-value="answer" @update:modelValue="update">
    <v-item
      v-for="option in options"
      :key="option.value"
      v-slot="{ isSelected, toggle }"
      :value="option.value"
    >
      <v-btn
        class="mr-2 mb-2"
        :variant="isSelected ? 'flat' : 'outlined'"
        color="secondary"
        @click="toggle"
        >{{ option.key }}</v-btn
      >
    </v-item>
  </v-item-group>
</template>

<script>
export default {
  name: "ButtonGroup",
  emits: ["change"],
  props: {
    answer: [String, Number],
    options: Array,
  },
  methods: {
    update(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style scoped></style>
