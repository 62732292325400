<template>
  <v-app>
    <v-layout>
      <v-app-bar>
        <v-app-bar-title>
          <v-img
            width="250"
            id="client-logo"
            src="@/assets/Current_Logo_White.svg"
          />
        </v-app-bar-title>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
#client-logo {
  margin-right: 2rem;
  margin-bottom: 1rem;
}
</style>
