<template>
  <v-container>
    <v-card
      title="Control Maturity"
      :subtitle="question.title"
      flat
      color="background"
      class="mx-auto d-flex flex-column"
    >
      <v-card-text>
        <v-container>
          <!--          <oscal-part-statement-->
          <!--            v-if="oscal"-->
          <!--            :statement="oscal.parts.find((p) => p.name === 'statement')"-->
          <!--            :parameters="oscal.params"-->
          <!--          >-->
          <!--            <template v-slot:parameter="{ parameter }">-->
          <!--              <oscal-parameter-->
          <!--                :parameters="oscal.params"-->
          <!--                :parameter="parameter"-->
          <!--              />-->
          <!--            </template>-->
          <!--          </oscal-part-statement>-->
          <!--          <br />-->
          <!--          <oscal-part-guidance-->
          <!--            v-if="oscal"-->
          <!--            :guidance="oscal.parts.find((p) => p.name === 'guidance')"-->
          <!--          ></oscal-part-guidance>-->
          {{ questionText }}
        </v-container>
        <v-card variant="outlined" class="mb-4">
          <v-card-title class="text-overline"> Maturity</v-card-title>
          <v-card-text>
            <button-group
              :answer="question.answer"
              :options="scaleOptions"
              @change="$emit('answer', $event)"
            />
          </v-card-text>
        </v-card>
        <v-card variant="outlined">
          <v-card-title class="d-flex text-overline">
            <div>Notes</div>
            <v-spacer />
            <v-btn @click="toggleNotes" icon="mdi-pencil-outline" />
          </v-card-title>
          <v-card-text>
            <div v-if="showNotesForm">
              <v-textarea v-model="localQuestion.notes" />
              <v-btn @click="submitNotes">Add Notes</v-btn>
            </div>
            <p v-else>{{ localQuestion.notes }}</p>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import ButtonGroup from "@/components/FormFields/ButtonGroup";
// import OscalParameter from "@/components/OSCAL/OscalParameter";
// import OscalPartGuidance from "@/components/OSCAL/OscalPartGuidance";
// import OscalPartStatement from "@/components/OSCAL/OscalPartStatement";
import { Question } from "@/entities/Question";

export default {
  name: "question-window",
  components: {
    ButtonGroup,
    // OscalParameter,
    // OscalPartGuidance,
    // OscalPartStatement,
  },
  props: {
    oscal: Object,
    question: Question,
    scaleOptions: {},
  },
  data() {
    return {
      showNotesForm: false,
      localQuestion: this.question,
    };
  },
  computed: {
    questionText() {
      let title = this.question.title;
      if (title.substr(-1, 1) === ".") title = title.replace(".", "?");
      return `The Organization, Information System, or System Element has formalized ${title}?
      Please mark the current level of maturity for this control below.`;
    },
  },
  methods: {
    toggleNotes() {
      this.showNotesForm = !this.showNotesForm;
    },
    async submitNotes() {
      await this.localQuestion.saveNotes();
      this.toggleNotes();
    },
  },
  created() {
    if (!this.question.notes) {
      this.toggleNotes();
    }
  },
};
</script>
