import { QuestionGroup } from "@/entities/QuestionGroup";
import { FirestoreService } from "@/services/FirestoreService";

export class CqnaAssessment {
  /**
   * @type FirestoreService
   */
  dbService;
  _id;
  _answers;
  _meta;
  _questionGroups;

  constructor(id, json) {
    this.dbService = new FirestoreService("cqna");
    const { answers = {}, meta, questionGroups } = json;

    this._id = id;
    this._answers = answers;
    this._meta = meta;
    this._questionGroups = questionGroups;
  }

  get id() {
    return this._id;
  }

  get answers() {
    return this._answers;
  }

  async setAnswer(key, data) {
    await this.setAnswers({ [key]: data });
  }

  async setAnswers(data) {
    let answers = { ...this.answers, ...data };

    await this.dbService.update(this.id, {
      answers,
    });

    this._answers = answers;
  }

  get meta() {
    return this._meta;
  }

  get questionGroups() {
    return Object.values(this._questionGroups).map((questionGroup) => {
      return new QuestionGroup(questionGroup, this);
    });
  }

  get completed() {
    return this.meta.completed;
  }

  async setCompleted() {
    let meta = { ...this.meta, completed: true };

    await this.dbService.update(this.id, {
      meta,
    });

    this._meta = meta;
  }

  getQuestionGroupById(id) {
    return this._questionGroups[id];
  }
}
