import { Question } from "@/entities/Question";
import { FirestoreService } from "@/services/FirestoreService";

export class QuestionGroup {
  _answers;
  _notes;
  _id;
  _questions;
  _title;
  _assessment;

  constructor(json, assessment) {
    const { answers, id, notes = {}, questions, title } = json;
    this._assessment = assessment;

    this._answers = answers;
    this._id = id;
    this._notes = notes;
    this._questions = questions;
    this._title = title;
  }

  get answers() {
    return this._answers;
  }

  get id() {
    return this._id;
  }

  get questions() {
    return Object.values(this._questions)
      .map((question) => new Question(question, this))
      .sort(function (a, b) {
        return a.id.localeCompare(b.id);
      });
  }

  get notes() {
    return this._notes;
  }

  get title() {
    return this._title;
  }

  get progress() {
    return Number.parseInt(
      (Object.keys(this.answers).length / this.questions.length) * 100
    );
  }

  get started() {
    return this.progress > 0 && !this.isComplete;
  }

  get isComplete() {
    return this.progress === 100;
  }

  async save(data) {
    const assessmentService = new FirestoreService("cqna");

    for (const key of Object.keys(data)) {
      const values = { ...this[key], ...data[key] };
      let updateData = {
        [`questionGroups.${this.id}.${key}`]: values,
      };

      await assessmentService.update(this._assessment.id, updateData);
    }
  }
}
