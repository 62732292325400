<template>
  <v-btn
    v-if="!questionGroup.started && !questionGroup.isComplete"
    color="secondary"
    @click="$emit('click:start', questionGroup)"
  >
    Start
  </v-btn>
  <v-btn
    v-if="questionGroup.started"
    color="secondary"
    @click="$emit('click:continue', questionGroup)"
  >
    In Progress
  </v-btn>
</template>
<script>
import { QuestionGroup } from "@/entities/QuestionGroup";

export default {
  name: "question-control-buttons",
  props: {
    questionGroup: QuestionGroup,
  },
  emits: ["click:start", "click:continue", "submit"],
};
</script>
