export class Question {
  _id;
  _text;
  _title;
  _questionGroup;

  constructor(json, questionGroup) {
    this._json = json;
    const { id, notes, text, title } = json;
    this._questionGroup = questionGroup;

    this._id = id;
    this._notes = notes;
    this._text = text;
    this._title = title;
  }

  get id() {
    return this._id;
  }

  get text() {
    return this._text;
  }

  set text(value) {
    this._text = value;
  }

  get title() {
    return this._title;
  }

  get answer() {
    return this._questionGroup.answers[this.id];
  }

  set answer(val) {
    this._questionGroup.answers[this.id] = val;
  }

  get notes() {
    return this._notes ?? this._questionGroup.notes[this.id];
  }

  set notes(value) {
    this._notes = value;
  }

  get hasAnswer() {
    return !!this.answer;
  }

  async saveNotes() {
    this._questionGroup.save({
      notes: { [this.id]: this._notes },
    });
  }
}
