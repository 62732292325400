// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDIWyp9QWbneXgg3hMRnIu-Y7_xwv7zZDY",
  authDomain: "zephyr-headwind.firebaseapp.com",
  databaseURL: "https://zephyr-headwind-default-rtdb.firebaseio.com",
  projectId: "zephyr-headwind",
  storageBucket: "zephyr-headwind.appspot.com",
  messagingSenderId: "805542643258",
  appId: "1:805542643258:web:333431b1cb1728e2899703",
  measurementId: "G-0ZKQ81B066",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const functions = getFunctions(app);

if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  // storage.useEmulator("localhost", 9199);
  // functionUrl = "http://localhost:5001/zephyr-headwind/us-central1";
}

export { firestore, functions };
