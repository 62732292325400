import { firestore } from "./firebase";
import {
  collection,
  deleteDoc,
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

export class FirestoreService {
  _collection;

  constructor(collection) {
    this._collection = collection;
  }

  async getAll() {
    let items = [];

    const querySnapshot = await getDocs(
      collection(firestore, this._collection)
    );

    querySnapshot.forEach((doc) => {
      items.push({ id: doc.id, ...doc.data() });
      console.log(`${doc.id} => ${doc.data()}`);
    });

    return items;
  }

  async getById(id) {
    const docRef = doc(firestore, this._collection, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  async add(data) {
    try {
      await addDoc(collection(firestore, this._collection), data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async update(id, data) {
    try {
      await updateDoc(doc(firestore, this._collection, id), data);
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  }

  async remove(id) {
    try {
      await deleteDoc(doc(firestore, this._collection, id));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  }
}
