<template>
  <v-card>
    <v-toolbar color="secondary">
      <v-toolbar-title>{{ businessUnit }}: My Information</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <p>
        Thank you for your participation in the Organizational Business Impact
        Analysis. You are vital to the process, and we appreciate your taking
        the time to complete this important work.
      </p>
      <p>
        A Business Impact Analysis (BIA) is a process that helps organizations
        identify and assess the potential effects of various types of
        disruptions on their operations, services, and overall business. You
        will be helping by:
      </p>
      <ol>
        <li>
          Defining your Business Unit's role and its place in the larger
          organization
        </li>
        <li>
          Identifying and defining the critical business processes your Business
          Unit performs or controls
        </li>
        <li>
          Identify the critical Information Systems used by each Business
          Process
        </li>
        <li>Provide descriptions and notes where possible</li>
      </ol>
      <p>
        Please provide descriptions and notes with as much detail as you are
        able. The information you provide will drive your Business Unit's BIA
        interview.
      </p>
      <v-form ref="form">
        <v-text-field
          label="Your Name"
          :rules="[(v) => !!v || 'Name is required.']"
          variant="outlined"
          class="mb-2"
          v-model="name"
        />
        <v-text-field
          label="Your Title"
          :rules="[(v) => !!v || 'Title is required.']"
          variant="outlined"
          class="mb-2"
          v-model="title"
        />
        <v-text-field
          label="Your Email"
          :rules="[(v) => !!v || 'Email is required.']"
          type="email"
          variant="outlined"
          class="mb-2"
          v-model="email"
        />
        <v-textarea
          label="Business Unit Description"
          variant="outlined"
          v-model="description"
        />
      </v-form>
      <v-btn block color="secondary" @click="submit">Submit</v-btn>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: "BiaInformation",
  props: {
    businessUnit: String,
    model: Object,
  },
  data() {
    return {
      name: "",
      title: "",
      email: "",
      description: "",
    };
  },
  methods: {
    async submit() {
      const { name, description, email, title } = this;
      const { valid } = await this.$refs.form.validate();

      if (valid) this.$emit("submit", { name, description, email, title });
    },
  },
  created() {
    if (this.model) {
      this.name = this.model.name;
      this.title = this.model.title;
      this.email = this.model.email;
      this.description = this.model.description;
    }
  },
};
</script>

<style>
p {
  padding-bottom: 1rem;
}
ol {
  padding: 1rem;
}
</style>
