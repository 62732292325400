<template>
  <v-card v-if="assessment.completed">
    <v-toolbar color="secondary">
      <v-toolbar-title> Data Submitted </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <p>
        Thank you for completing the assessment. Your data has been submitted
        for review.
      </p>
    </v-container>
  </v-card>
  <bia-information
    v-else-if="!this.hasContact || showContact"
    :business-unit="assessment.meta.name"
    :model="assessment.answers.contact"
    @submit="handleInformation"
  />
  <bia-processes
    v-else
    :assessment="assessment"
    @open-contact="showContact = true"
  />
</template>

<script>
import { CqnaAssessment } from "@/entities/CqnaAssessment";
import BiaProcesses from "@/components/BiaProcesses.vue";
import BiaInformation from "@/components/BiaInformation.vue";

export default {
  name: "BiaPrework",
  components: { BiaInformation, BiaProcesses },
  props: { assessment: CqnaAssessment },
  data() {
    return {
      showContact: false,
    };
  },
  computed: {
    hasContact() {
      return Object.keys(this.assessment.answers).includes("contact");
    },
  },
  methods: {
    handleInformation(data) {
      this.assessment.setAnswer("contact", data);

      this.showContact = false;
    },
  },
};
</script>
