<template>
  <v-window :model-value="window" direction="vertical" class="fill-height">
    <questions-window
      :oscal="oscal"
      :scale-options="scaleOptions"
      :questions="selected.questions"
      @change="updateAnswer"
    />
    <v-window-item class="pl-16 pr-16">
      <v-container>
        <v-card title="Complete Assessment">
          <v-card-text>
            <v-container
              class="d-flex align-content-center"
              v-if="selected.isComplete"
            >
              <v-btn @click="$emit('home')" color="secondary"
                >Return To Home</v-btn
              >
            </v-container>
            <v-alert v-else type="warning">
              <div>
                <p>
                  Please answer all questions. Once complete, your answers may
                  be submitted.
                </p>
              </div>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-container>
    </v-window-item>
  </v-window>
</template>
<script>
// import axios from "axios";
import QuestionsWindow from "@/components/Questions";

export default {
  name: "question-group-window",
  components: {
    QuestionsWindow,
  },
  props: {
    scaleOptions: {},
    selected: {},
    window: Number,
  },
  inject: ["assessmentId"],
  data() {
    return {
      oscal: null,
      localAnswers: this.selected.answers,
    };
  },
  // watch: {
  //   window(index) {
  //     this.getText(this.selected.questions[index]);
  //   },
  // },
  methods: {
    // async getText(question) {
    //   let http = axios.create();
    //   let axiosResponse = await http.get(
    //     `http://localhost:5001/zephyr-headwind/us-central1/controls/${question.id}`
    //   );
    //
    //   this.oscal = await axiosResponse.data;
    // },
    async updateAnswer(question, value) {
      // const assessmentService = new FirestoreService("cqna");

      const answers = { ...this.selected.answers, [question.id]: value };
      await this.selected.save({ answers });

      // await assessmentService.update(this.assessmentId, {
      //   [`questionGroups.${this.selected.id}.answers`]: answers,
      // });

      question.answer = value;
    },
  },
  // async created() {
  //   await this.getText(this.selected.questions[0]);
  // },
};
</script>
