<template>
  <v-card>
    <v-toolbar color="secondary">
      <v-toolbar-title>
        System Element Maturities
        {{ selected ? `- ${selected.title}` : "" }}
      </v-toolbar-title>
      <v-spacer />
      <v-menu v-if="selected">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-format-list-group" v-bind="props" />
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in selected.questions"
            :key="index"
            :value="index"
            @click="window = index"
          >
            <template v-if="item.hasAnswer" #prepend>
              <v-icon color="secondary" class="mr-2">mdi-check</v-icon>
            </template>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn icon="mdi-home" @click="select(null)" />
    </v-toolbar>
    <v-container v-if="!selected">
      <system-element-list
        :system-elements="assessment.questionGroups"
        @selected="select"
      />
    </v-container>
    <v-container v-if="selected" fluid>
      <v-progress-linear
        :model-value="selected.progress"
        color="#0552E9"
        height="25"
        rounded
      >
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
      <question-group-window
        :scale-options="scaleOptions"
        :selected="selected"
        :window="window"
        @next="next"
        @prev="prev"
        @home="select(null)"
      />
    </v-container>
    <v-card-actions v-if="selected">
      <v-btn v-if="window > 0" @click="prev">Previous</v-btn>
      <v-spacer />
      <v-btn v-if="window < selected.questions.length" @click="next">
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import QuestionGroupWindow from "@/components/QuestionGroupWindow";
import SystemElementList from "@/components/SystemElementList";
import { CqnaAssessment } from "@/entities/CqnaAssessment";

export default {
  name: "SystemElementMaturities",
  components: { SystemElementList, QuestionGroupWindow },
  props: { assessmentId: String, assessment: CqnaAssessment },
  data() {
    return {
      window: 0,
      selected: null,
      answers: {},
      scaleOptions: [
        { key: "100%", value: "10" },
        { key: "90%", value: "9" },
        { key: "80%", value: "8" },
        { key: "70%", value: "7" },
        { key: "60%", value: "6" },
        { key: "50%", value: "5" },
        { key: "40%", value: "4" },
        { key: "30%", value: "3" },
        { key: "20%", value: "2" },
        { key: "10%", value: "1" },
        { key: "0%", value: "0" },
        { key: "N/A", value: "na" },
      ],
    };
  },
  methods: {
    select(systemElement = null) {
      this.window = 0;
      this.selected = systemElement;
    },
    next() {
      this.window++;
    },
    prev() {
      this.window--;
    },
  },
};
</script>
